import {
  GET_FLIGHT_TIME,
  GET_TIME_ZONE_DEPART,
  GET_TIME_ZONE_ARRIVE,
  VALIDATE_FLIGHT_TIME,
  SET_RETURN_FLIGHT_TIME,
  VALIDATE_RETURN_FLIGHT_TIME,
  SET_RETURN_FLIGHT_TIME_ZONE,
  SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE
} from '../actions/constants';

const initialState = {
  flightTime: '',
  timeZoneDepart: '',
  timeZoneArrive: '',
  isTimeValid: null,
  returnFlightTime: "",
  isReturnFlightTimeValid: null,
  returnFlightTimeZone: "",
  returnDisabledDate: "",
};

const flightTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLIGHT_TIME:
      return { ...state, flightTime: action.payload };
    case VALIDATE_FLIGHT_TIME:
      return { ...state, isTimeValid: action.payload };
    case GET_TIME_ZONE_DEPART:
      return { ...state, timeZoneDepart: action.payload };
    case GET_TIME_ZONE_ARRIVE:
      return { ...state, timeZoneArrive: action.payload };
    case SET_RETURN_FLIGHT_TIME:
      return { ...state, returnFlightTime: action.payload };
    case VALIDATE_RETURN_FLIGHT_TIME:
      return { ...state, isReturnFlightTimeValid: action.payload };
    case SET_RETURN_FLIGHT_TIME_ZONE:
      return { ...state, returnFlightTimeZone: action.payload };
    case SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE:
      return { ...state, returnDisabledDate: action.payload };
    default:
      return state;
  }
};

export default flightTimeReducer;
