import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './reducers/rootReducer';

const isProd = process.env.NODE_ENV === 'production';
const middleware = applyMiddleware(logger, thunk);

export default () => {
  let store = createStore(reducer, isProd ? applyMiddleware(thunk) : composeWithDevTools(middleware));
  // let store = createStore(reducer, applyMiddleware(thunk) );

  return { store };
};
