import { SHOW_MARKER_INFO, SHOW_MARKER } from '../actions/constants';
import { CLOSE_MARKER_INFO } from '../actions/constants';

const initialState = {
  markerinfo: false,
  isVisible: false,
  name: ''
};

const mapsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MARKER_INFO:
      return { ...state, markerinfo: true, name: action.payload };
    
    case SHOW_MARKER:
      return { ...state, isVisible: false, };

    case CLOSE_MARKER_INFO:
      return { ...state, markerinfo: false };

    default:
      return state;
  }
};

export default mapsReducer;
