import {
  GET_DEPART_AIRPORT,
  GET_ARRIVE_AIRPORT,
  GET_PASSENGER_NUMBER,
  GET_ARRIVE_AIRPORT_COORDS,
  GET_DEPART_AIRPORT_COORDS,
  VALIDATE_DEPART_AIRPORT,
  VALIDATE_ARRIVE_AIRPORT,
  CHECK_FLIGHT_SEARCH_COMPLETION,
  GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG,
  SET_DEPART_DOMESTIC_FLIGHT_FLAG,
  SET_ARRIVE_DOMESTIC_FLIGHT_FLAG,
  SWITCH_FLIGHT_SEARCH_FORM_TYPE,
  SWITCH_AIRFIELDS
} from '../actions/constants';

const initialState = {
  departAirport: '',
  arriveAirport: '',
  passengers: 1,
  arriveCoords: {},
  departCoords: {},
  isDepartAirportValid: null,
  isArriveAirportValid: null,
  isCompleted: false,
  paxMultiLeg: 0,
  departDomesticFlight: {
    countryName: "",
    flag: 0
  },
  arriveDomesticFlight: {
    countryName: "",
    flag: 0
  },
  formType: "one-way",
  areSwitched: false
};

const flightSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPART_AIRPORT:
      return { ...state, departAirport: action.payload };
    case GET_ARRIVE_AIRPORT:
      return { ...state, arriveAirport: action.payload };
    case GET_ARRIVE_AIRPORT_COORDS:
      return { ...state, arriveCoords: action.payload };
    case GET_DEPART_AIRPORT_COORDS:
      return { ...state, departCoords: action.payload };
    case GET_PASSENGER_NUMBER:
      return { ...state, passengers: action.payload };
    case VALIDATE_DEPART_AIRPORT:
      return { ...state, isDepartAirportValid: action.payload };
    case VALIDATE_ARRIVE_AIRPORT:
      return { ...state, isArriveAirportValid: action.payload };
    case CHECK_FLIGHT_SEARCH_COMPLETION:
      return { ...state, isCompleted: action.payload };
    case SET_DEPART_DOMESTIC_FLIGHT_FLAG:
      return { ...state, departDomesticFlight: action.payload };
    case SET_ARRIVE_DOMESTIC_FLIGHT_FLAG:
      return { ...state, arriveDomesticFlight: action.payload };
    case GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG:
        return { ...state, paxMultiLeg: action.payload };
    case SWITCH_FLIGHT_SEARCH_FORM_TYPE:
      return { ...state, formType: action.payload };
    case SWITCH_AIRFIELDS:
      return { ...state, areSwitched: action.payload };
    default:
      return state;
  }
};

export default flightSearchReducer;
