import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './src/state/store';

const { store } = configureStore();

export const wrapPageElement = ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store}>
      {element}
    </Provider>
  )
  
  return ConnectedRootElement;
};

export const onInitialClientRender = () => {
  sessionStorage.removeItem('customTriggerFired');
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
        ReactDOM.render(element, container, callback);
    });
  };
}; 
