import { combineReducers } from 'redux';

import mapsReducer from './maps';
import flightSearchReducer from './flightSearch';
import flightSearchMapReducer from './flightSearchMap';
import flightTimeReducer from './flightTime';
import aircraftFilterReducer from './aircraftFilter';
import findNearestAirportReducer from './findNearestAirports';
import drawerReducer from './drawer';
import searchResultsReducer from './searchResults';
import languageReducer from './language';
import phoneNumberReducer from './phoneNumber';
import trustPilotReducer from './trustPilot';

export default combineReducers({
  mapsReducer,
  drawerReducer,
  flightSearchReducer,
  flightTimeReducer,
  flightSearchMapReducer,
  aircraftFilterReducer,
  findNearestAirportReducer,
  languageReducer,
  searchResultsReducer,
  phoneNumberReducer,
  trustPilotReducer
});
