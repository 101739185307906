import {
    ONOPENMODAL,
    ONDEPARTSELECT,
    ONARRIVESELECT,
    NEWAIRPORTSELECT,
    ONCLOSEMODAL,
    RESET
  } from '../actions/constants';
  
  const initialState = {
    openModal: false,
    airportSelected: null,
    depart: null,
    isDepartSelected: null,
    isArriveSelected: null,
    onPlaceChanged: null
  };
  
  const findNearestAirportReducer = (state = initialState, action) => {
    switch (action.type) {
      case ONOPENMODAL:
        return { ...state, openModal: true, depart: action.payload };
      case ONDEPARTSELECT:
        return { ...state, isDepartSelected: action.payload, depart: action.payload.depart };
      case ONARRIVESELECT:
        return { ...state, isArriveSelected: action.payload, depart: action.payload.depart };
      case NEWAIRPORTSELECT:
        return { ...state, airportSelected: action.payload };
      case ONCLOSEMODAL:
        return { ...state, openModal: action.payload, onPlaceChanged: action.payload };
      case RESET:
        return { ...state, airportSelected: action.payload, depart: action.payload };
      default:
        return state;
    }
  };
  
  export default findNearestAirportReducer;
  