
import { SET_PHONE_NUMBER } from '../actions/constants';

const initialState = {
  phoneNumber: ''
};

const phoneNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload };
    default:
      return state;
  }
};

export default phoneNumberReducer;
