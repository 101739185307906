import { PRELOAD_FLIGHT_SEARCH_MAP } from '../actions/constants';

const initialState = {
  isPreloaded: false
};

const flightSearchMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRELOAD_FLIGHT_SEARCH_MAP:
      return { ...state, isPreloaded: action.payload };

    default:
      return state;
  }
};

export default flightSearchMapReducer;
