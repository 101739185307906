import { TOOGLE_BUTTON } from '../actions/constants';

const initialState = {
  isActive: ''
};

const aircraftFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOOGLE_BUTTON:
      return { ...state, isActive: action.payload };
    default:
      return state;
  }
};

export default aircraftFilterReducer;
