
import { LOAD_TRUSTPILOT_SCRIPT } from '../actions/constants';

const initialState = {
  shouldLoadTrustPilotScript: false
};

const trustPilotReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRUSTPILOT_SCRIPT:
      return { ...state, shouldLoadTrustPilotScript: action.payload };
    default:
      return state;
  }
};

export default trustPilotReducer;
