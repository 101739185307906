import { GENERATE_SEARCH_RESULTS } from '../actions/constants';

const initialState = {
  results: []
};

const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_SEARCH_RESULTS:
      return { ...state, results: action.payload };

    default:
      return state;
  }
};

export default searchResultsReducer;
