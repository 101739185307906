
export const SHOW_MARKER_INFO = 'SHOW_MARKER_INFO';
export const SHOW_MARKER = 'SHOW_MARKER';
export const CLOSE_MARKER_INFO = 'CLOSE_MARKER_INFO';

//flight search

export const GET_PASSENGER_NUMBER = 'GET_PASSENGER_NUMBER';
export const GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG = 'GET_PASSENGER_NUMBER_INITIAL_MULTI_LEG';

export const GET_FLIGHT_TIME = 'GET_FLIGHT_TIME';
export const VALIDATE_FLIGHT_TIME = 'VALIDATE_FLIGHT_TIME';

export const GET_TIME_ZONE_DEPART = 'GET_TIME_ZONE_DEPART';
export const GET_TIME_ZONE_ARRIVE = 'GET_TIME_ZONE_ARRIVE';

export const GET_DEPART_AIRPORT = 'GET_DEPART_AIRPORT';
export const GET_ARRIVE_AIRPORT = 'GET_ARRIVE_AIRPORT';

export const GET_DEPART_AIRPORT_COORDS = 'GET_DEPART_AIRPORT_COORDS';
export const GET_ARRIVE_AIRPORT_COORDS = 'GET_ARRIVE_AIRPORT_COORDS';

export const VALIDATE_DEPART_AIRPORT = 'VALIDATE_DEPART_AIRPORT';
export const VALIDATE_ARRIVE_AIRPORT = 'VALIDATE_ARRIVE_AIRPORT';

export const SET_DEPART_DOMESTIC_FLIGHT_FLAG = 'SET_DEPART_DOMESTIC_FLIGHT_FLAG';
export const SET_ARRIVE_DOMESTIC_FLIGHT_FLAG = 'SET_ARRIVE_DOMESTIC_FLIGHT_FLAG';

export const PRELOAD_FLIGHT_SEARCH_MAP = 'PRELOAD_FLIGHT_SEARCH_MAP';

export const TOOGLE_BUTTON = 'TOOGLE_BUTTON';

export const CHECK_FLIGHT_SEARCH_COMPLETION = 'CHECK_FLIGHT_SEARCH_COMPLETION';

//find neareast airports
export const ONOPENMODAL = 'ONOPENMODAL';
export const ONDEPARTSELECT = 'ONDEPARTSELECT';
export const ONARRIVESELECT = 'ONARRIVESELECT';
export const NEWAIRPORTSELECT = 'NEWAIRPORTSELECT';
export const ONCLOSEMODAL = 'ONCLOSEMODAL';
export const RESET = 'RESET';

//header

export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const GENERATE_SEARCH_RESULTS = "GENERATE_SEARCH_RESULTS";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const LOAD_TRUSTPILOT_SCRIPT = "LOAD_TRUSTPILOT_SCRIPT";

export const SWITCH_FLIGHT_SEARCH_FORM_TYPE = "SWITCH_FLIGHT_SEARCH_FORM_TYPE";

export const SET_RETURN_FLIGHT_TIME = "SET_RETURN_FLIGHT_TIME";
export const VALIDATE_RETURN_FLIGHT_TIME = "VALIDATTE_RETURN_FLIGHT_TIME";
export const SET_RETURN_FLIGHT_TIME_ZONE = "SET_RETURN_FLIGHT_TIME_ZONE";
export const SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE = "SET_RETURN_FLIGHT_CALENDAR_DISABLED_DATE";
export const SWITCH_AIRFIELDS = "SWITCH_AIRFIELDS";

