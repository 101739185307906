import { CLOSE_DRAWER } from '../actions/constants';

const initialState = {
  isMenuClosed: null
};

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_DRAWER:
      return { ...state, isMenuClosed: action.payload };
    default:
      return state;
  }
};

export default drawerReducer;